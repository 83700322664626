
import { ref, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { RoofSideBar, RoofTopBar } from '@squareroof-admin/ui-components'
import { tabTitle } from '../composables'

export default defineComponent({
  components: {
    RoofSideBar,
    RoofTopBar,
  },
  name: 'DashboardLayout',
  setup() {
    const router = useRouter()

    const selectedTab = ref('')

    const tabIsActive = (tabName: string) => {
      const mainName = tabName

      if (mainName == 'base' && router.currentRoute.value.path == '/') {
        return true
      } else if (
        mainName != 'base' &&
        router.currentRoute.value.path.includes(mainName)
      ) {
        selectedTab.value = mainName
        return true
      }

      return false
    }

    const goBack = () => {
      window.history.length > 1 ? router.go(-1) : router.push('/')
    }

    const goToRoute = (route: string) => {
      router.push(route)
    }

    const loaderSetup = ref<any>()

    const tabs = ref<any[]>([
      {
        name: 'Dashboard',
        path: '/',
        icon: 'dashboard',
        routeTag: 'base',
        icon_size: 'lg:h-[17px] mdlg:h-[16px]',
      },
      {
        name: 'Services',
        path: '/services',
        icon: 'product',
        routeTag: 'services',
        icon_size: 'lg:h-[16px] mdlg:h-[16px]',
        sub: [
          {
            name: 'Assessments',
            path: '/services/assessments',
          },
          {
            name: 'Fines',
            path: '/services/fines',
          },
        ],
      },
      {
        name: 'Bookings',
        path: '/bookings',
        icon: 'booking',
        routeTag: 'bookings',
        icon_size: 'lg:h-[17px] mdlg:h-[16px]',
      },
      {
        name: 'Transactions',
        path: '/financials',
        icon: 'financials',
        sub: [
          {
            name: 'Coupons',
            path: '/financials/coupons',
          },
          {
            name: 'Referrals',
            path: '/financials/referrals',
          },
        ],
        routeTag: 'financials',
        icon_size: 'lg:h-[17px] mdlg:h-[16px]',
        showSub: false,
      },
      {
        name: 'Users',
        path: '/users',
        icon: 'user',
        routeTag: 'users',
        icon_size: 'lg:h-[17px] mdlg:h-[16px]',
        showSub: false,
        sub: [
          {
            name: 'Users list',
            path: '/users/list',
          },
          {
            name: 'Engagments',
            path: '/users/engagements',
          },
        ],
      },
      {
        name: 'Providers',
        path: '/providers',
        icon: 'provider',
        routeTag: 'providers',
        icon_size: 'lg:h-[19px] mdlg:h-[18px]',
        showSub: false,
        sub: [
          {
            name: 'Providers list',
            path: '/providers/list',
          },
        ],
      },
      {
        name: 'Admins',
        path: '/admins',
        icon: 'admin-user',
        routeTag: 'admins',
        icon_size: 'lg:h-[19px] mdlg:h-[18px]',
      },
      {
        name: 'Activity log',
        path: '/activity-log',
        icon: 'log',
        routeTag: 'activity-log',
        icon_size: 'lg:h-[16px] mdlg:h-[16px]',
      },
    ])

    return {
      tabIsActive,
      goBack,
      goToRoute,
      tabTitle,
      loaderSetup,
      tabs,
    }
  },
})

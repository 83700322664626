import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppLayout = _resolveComponent("AppLayout")!
  const _component_roof_loader = _resolveComponent("roof-loader")!
  const _component_roof_side_info_box = _resolveComponent("roof-side-info-box")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content} | Unwind Admin` : `Unwind Admin`), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }),
    (_ctx.loaderSetup.show)
      ? (_openBlock(), _createBlock(_component_roof_loader, {
          key: 0,
          loaderSetup: _ctx.loaderSetup,
          close: 
      () => {
        _ctx.loaderSetup.show = false
      }
    
        }, null, 8, ["loaderSetup", "close"]))
      : _createCommentVNode("", true),
    (_ctx.sideBarInfo.show)
      ? (_openBlock(), _createBlock(_component_roof_side_info_box, {
          key: 1,
          data: _ctx.sideBarInfo,
          isLoading: _ctx.sideBarInfo.formIsLoading,
          close: 
      () => {
        _ctx.sideBarInfo.show = false
      }
    ,
          "can-close": false
        }, null, 8, ["data", "isLoading", "close"]))
      : _createCommentVNode("", true)
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed top-0 left-0 h-full overflow-y-auto scrollbar-hidden lg:w-[16%] mdlg:w-[16%] mdlg:flex hidden px-6 py-5 !pt-0 flex-col !bg-primaryDark space-y-3" }
const _hoisted_2 = { class: "h-full lg:w-[84%] mdlg:w-[84%] w-full text-center !bg-primaryDark relative px-0 mdlg:px-0 mdlg:ml-[16%] lg:ml-[16%] flex flex-row lg:text-sm mdlg:text-[12px] text-xs" }
const _hoisted_3 = { class: "mdlg:w-[100%] lg:w-[100%] h-full w-full flex rounded-md flex-col items-center" }
const _hoisted_4 = { class: "w-full h-full flex flex-grow flex-col relative px-2" }
const _hoisted_5 = { class: "w-full flex flex-col h-full min-h-screen lg:!px-8 lg:!py-5 px-3 py-3 justify-start items-center rounded-xl text-bodyLight bg-white" }
const _hoisted_6 = { class: "flex flex-col w-full flex-grow h-full items-start justify-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_roof_side_bar = _resolveComponent("roof-side-bar")!
  const _component_roof_top_bar = _resolveComponent("roof-top-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_roof_side_bar, {
        tabIsActive: _ctx.tabIsActive,
        tabs: _ctx.tabs,
        logoPath: '/images/logo-text.svg',
        logoSize: 'w-[150px]'
      }, null, 8, ["tabIsActive", "tabs", "logoPath"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_roof_top_bar, {
          goBack: _ctx.goBack,
          goToRoute: _ctx.goToRoute
        }, null, 8, ["goBack", "goToRoute"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
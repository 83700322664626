
import { useMeta } from 'vue-meta'
import { RoofLoader, RoofSideInfoBox } from '@squareroof-admin/ui-components'
import { defineComponent, onMounted, ref } from 'vue'
import { Logic } from '@unwind-admin/logic'

export default defineComponent({
  components: {
    RoofLoader,
    RoofSideInfoBox,
  },
  setup() {
    useMeta({
      title: 'Home',
      htmlAttrs: { lang: 'en', amp: true },
    })

    const loaderSetup = ref<any>()
    loaderSetup.value = Logic.Common.loaderSetup

    const sideBarInfo = ref<any>()
    sideBarInfo.value = Logic.Common.sideBarInfo

    const importantColors = ['border-primaryColor']

    onMounted(() => {
      Logic.Common.watchProperty('loaderSetup', loaderSetup)
      Logic.Common.watchProperty('sideBarInfo', sideBarInfo)
    })

    return {
      loaderSetup,
      sideBarInfo,
    }
  },
})
